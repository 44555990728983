<template>
<div> 
  <div class="wrapper"> 
    <div class="main-panel" style="background: rgb(224, 229, 249); min-height: 100vh;padding-bottom: 30px;">
      <TopNavbarLanding />
      <div class="container" style="background: rgb(224, 229, 249)">
        <div class="row align-content-reg">
          <div class="col-md-8 col-sm-12">
            <div class="classWell">

                <transition name="fade" mode="out-in">
                <!-- your content here -->
                <router-view></router-view>
                </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import TopNavbarLanding from './MerchantDashboard/TopNavbarlanding.vue'
import DashboardContent from './MerchantDashboard/Content.vue'
export default {
  components: {
    TopNavbarLanding,
    DashboardContent
  },
  methods: {
  }
}
</script>
<style>
.navbar-logo{padding-bottom: 10px;}
</style>
<style scoped>
  .fade-enter-active,
  .fade-leave-active {
    transition: opacity .15s
  }

  .fade-enter,
  .fade-leave-to
    /* .fade-leave-active in <2.1.8 */

  {
    opacity: 0
  }
  .align-content-reg{
      display: flex;
      align-content: center;
      justify-content: center;
  }
@media (min-width: 768px){
  .classWell {
    min-height: 80vh;
    /* display: flex;
    align-items: center;
    justify-content: center; */
  }
}
@media (max-width: 1024px) and (min-width: 768px){
  .classWell {
    min-height: 80vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1280px) {
  .classWell {
    min-height: 85.6vh
  }
}
@media (min-width: 1440px) {
  .classWell {
    min-height: 76vh;
  }
}
@media (min-width: 1800px) {
  .classWell {
    min-height: 38vh;
  }
}
@media (min-width: 1858px) {
  .classWell {
    min-height: 71vh;
  }
}
@media (min-width: 1880px) {
  .classWell {
    min-height: 38vh;
  }
}
@media (min-width: 2880px) {
  .classWell {
    min-height: 38vh;
  }
}
</style>